import vodiceImg from '../../../../assets/imgs/home/02_destinations/vodice.jpg';
import sibenikImg from '../../../../assets/imgs/home/02_destinations/sibenik.jpg';
import zadarImg from '../../../../assets/imgs/home/02_destinations/zadar.jpg';
import splitImg from '../../../../assets/imgs/home/02_destinations/split.jpg';
import krkaImg from '../../../../assets/imgs/home/02_destinations/krka.jpg';
import kornatiImg from '../../../../assets/imgs/home/02_destinations/kornati.jpg';
import npImg from '../../../../assets/imgs/home/02_destinations/2_ocaravajuca_nacionalna_parka.jpg';
import castleImg from '../../../../assets/imgs/home/02_destinations/11_srednjovjekovnih_utvrda.jpg';
import cultureImg from '../../../../assets/imgs/home/02_destinations/230_spomenika_kulture.jpg';
import heritageImg from '../../../../assets/imgs/home/02_destinations/2_lokaliteta_na_popisu_svjetske_bastine.jpg';

interface Destination {
  title: string;
  link: string;
  img: string;
}
const DestinationsList = ({ lang }: { lang: Locale.Keys }) => {
  const DestinationList: Array<Destination> = [
    {
      title: 'Krka',
      link: 'https://www.npkrka.hr/hr/',
      img: krkaImg,
    },
    {
      title: 'Vodice',
      link: 'https://www.visit-vodice.eu/en/home/',
      img: vodiceImg,
    },
    {
      title: 'Šibenik',
      link: 'https://www.dalmatiasibenik.hr/en/explore/coast/sibenik/',
      img: sibenikImg,
    },
    {
      title: 'Kornati',
      link: 'https://www.kornati.hr/',
      img: kornatiImg,
    },
    {
      title: 'Split',
      link: 'https://visitsplit.com/',
      img: splitImg,
    },
    {
      title: 'Zadar',
      link: 'https://zadar.travel/hr/',
      img: zadarImg,
    },
  ];

  const HeritageList: Array<Destination> = [
    {
      title: lang.home.destinations.heritage.culture,
      link: 'https://www.dalmatiasibenik.hr/hr/clanci/top/230-spomenika-kulture/',
      img: cultureImg,
    },
    {
      title: lang.home.destinations.heritage.np,
      link: 'https://www.dalmatiasibenik.hr/hr/clanci/top/2-ocaravajuca-nacionalna-parka/',
      img: npImg,
    },
    {
      title: lang.home.destinations.heritage.castle,
      link: 'https://www.dalmatiasibenik.hr/hr/clanci/top/11-srednjovjekovnih-utvrda/',
      img: castleImg,
    },
    {
      title: lang.home.destinations.heritage.heritage,
      link: 'https://www.dalmatiasibenik.hr/hr/clanci/top/2-lokaliteta-na-popisu-svjetske-bastine-unesco-a/',
      img: heritageImg,
    },
  ];

  return { DestinationList, HeritageList };
};

export default DestinationsList;
