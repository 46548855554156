import { useEffect, useState } from 'react';
import Constants from '../../utils/Constants';

const images = require.context('../../assets/imgs/home/00_hero/', true);
const imageList = images?.keys().map((image: any) => images(image));

const IMAGE_CONTAINER = 'hero_slideshow_image_container';
const ACTIVE_SLIDE = 'slide_active';
const LAST_SLIDE = 'slide_last';
const TIME_OUT = 5; // duration of transition in seconds

// duplicate first image to the last position
imageList?.push(imageList[0]);

const HeroSlideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageContainer = document.getElementById(IMAGE_CONTAINER);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, TIME_OUT * 1000);

    return () => clearInterval(interval);
  }, []);

  if (imageContainer !== null) {
    setTimeout(
      () => {
        if (currentIndex === imageList.length - 1) {
          imageContainer.classList.add(LAST_SLIDE);
          setCurrentIndex(0);
        } else if (currentIndex === 0) {
          imageContainer.classList.remove(LAST_SLIDE);
        }
      },
      currentIndex === 0 ? 100 : 1100
    );
  }

  return (
    <div id='hero_slideshow'>
      <div
        id={IMAGE_CONTAINER}
        className={ACTIVE_SLIDE}
        style={{
          width: `${imageList.length * 100}vw`,
          transform: `translateX(${-currentIndex * 100}vw)`,
        }}
      >
        {imageList.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`hero ${Constants.PROPERTY_NAME} ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroSlideshow;
