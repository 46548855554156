import HeroSlideshow from '../../components/03_hero_slideshow/HeroSlideshow';
import Constants from '../../utils/Constants';
import About from './components/00_about/About';
import FacilitiesAndServices from './components/01_facilitiesAndServices/FacilitiesAndServices';
import Accommodations from './components/02_accommodations/Accommodations';
import Destinations from './components/03_destinations/Destinations';
import Gallery from './components/04_gallery/Gallery';

const gallery = require.context('../../assets/imgs/home/03_gallery', true);
const galleryList = gallery.keys().map((image: any) => gallery(image));

const Home = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id={Constants.HOME} className='home'>
      <div className='home_container_hero'>
        <HeroSlideshow />
        <div className='home_container_hero_button'>
          <a
            href={Constants.bookNowLink}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>{lang.bookNow.toUpperCase()}</div>
          </a>
        </div>
      </div>
      <h1 hidden>{Constants.PROPERTY_NAME}</h1>
      <About lang={lang} />
      <FacilitiesAndServices lang={lang} />
      <Accommodations lang={lang} />
      <Destinations lang={lang} />
      <Gallery lang={lang} imageList={galleryList} />
    </div>
  );
};

export default Home;
