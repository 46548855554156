import { useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en';
import { hr } from './hr';
import { de } from './de';
import { fr } from './fr';
import Constants from '../utils/Constants';

const storedLanguage = localStorage.getItem(Constants.LANG_STORAGE_KEY);

i18n.use(initReactI18next).init({
  lng: storedLanguage || Constants.lang.en,
  fallbackLng: Constants.lang.en,
  resources: {
    hr: {
      translation: hr,
    },
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    fr: {
      translation: fr,
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

export function useLanguageSwitcher() {
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage && i18n.language !== storedLanguage) {
      changeLanguage(storedLanguage);
    } else {
      setCurrentLanguage(i18n.language);
    }
  }, []);

  function changeLanguage(language: string) {
    localStorage.setItem('selectedLanguage', language);
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
    updateLanguageInURL(language);
  }

  function updateLanguageInURL(language: string) {
    const url = new URL(window.location.href);
    url.searchParams.set('lang', language);
    window.history.replaceState({}, '', url.toString());
  }

  function getLanguageFromURL() {
    const url = new URL(window.location.href);
    const langParam = url.searchParams.get('lang');
    if (langParam) {
      changeLanguage(langParam);
    }
  }

  return { currentLanguage, changeLanguage, getLanguageFromURL };
}
