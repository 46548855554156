import { useLocation, useNavigate } from 'react-router-dom';
import rooms from '../../../../assets/imgs/00_accommodations/01_rooms/04.webp';
import suites from '../../../../assets/imgs/00_accommodations/02_suites/03.webp';
import scrollToPage from '../../../../utils/ScrollToLink';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';

interface Accommodation {
  id: string;
  title: string;
  p1: string;
  p2: string;
  p3: string;
  link: string;
  imgPath: string;
}

const Accommodations = ({ lang }: { lang: Locale.Keys }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const accommodations: Array<Accommodation> = [
    {
      id: Constants.ROOMS,
      title: lang.home.accommodations.rooms.title,
      p1: lang.home.accommodations.rooms.p1,
      p2: lang.home.accommodations.rooms.p2,
      p3: lang.home.accommodations.rooms.p3,
      link: Constants.ROOMS,
      imgPath: rooms,
    },
    {
      id: Constants.SUITES,
      title: lang.home.accommodations.suites.title,
      p1: lang.home.accommodations.suites.p1,
      p2: lang.home.accommodations.suites.p2,
      p3: lang.home.accommodations.suites.p3,
      link: Constants.SUITES,
      imgPath: suites,
    },
  ];

  return (
    <div id={Constants.ACCOMMODATIONS} className='accommodations'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeIn' delay={100}>
            <h2 className='text_center'>{lang.home.accommodations.title}</h2>
          </ScrollAnimation>
          {accommodations.map((accommodation: Accommodation) => (
            <ScrollAnimation animateIn='fadeIn' delay={100}>
              <div
                id={accommodation.id}
                className='accommodations_slick_container'
              >
                <div className='accommodations_slick_container_title'>
                  <h3>{accommodation.title.toUpperCase()}</h3>
                  <p>{accommodation.p1}</p>
                  <p>{accommodation.p2}</p>
                  <p>{accommodation.p3}</p>
                </div>
                <img
                  src={accommodation.imgPath}
                  alt={accommodation.title}
                  className='accommodations_slick_container_image'
                />
                <div
                  data-index={`${accommodations.indexOf(accommodation)}`}
                  className='accommodations_slick_container_box'
                >
                  <div className='accommodations_slick_container_box_title title_underline_complementary_small'>
                    {accommodation.title}
                  </div>
                  <div className='accommodations_slick_container_box_links'>
                    <a
                      href={Constants.bookNowLink}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div>{lang.bookNow.toUpperCase()}</div>
                    </a>
                    <div
                      onClick={() =>
                        scrollToPage(
                          accommodation.link,
                          location,
                          navigate,
                          true
                        )
                      }
                    >
                      {lang.showMore.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          ))}
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Accommodations;
