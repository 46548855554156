export const fr: Locale.Keys = {
  bookNow: 'Reserve',
  showMore: 'Montre plus',
  home: {
    menu: 'home',
    about: {
      title: 'sidro - vodice',
      p1: `La Maison d'hôtes Sidro est une petite auberge familiale gérée par la famille Marić, avec une tradition vieille de 40 ans.`,
      p2: `Elle est située dans une partie calme de Vodice, à seulement 150 mètres de la plage principale de la ville et à 500 mètres du centre-ville. L'établissement est entouré d'une vaste terrasse en pierres, de plantes méditerranéennes et de verdure. C'est l'endroit idéal pour se détendre et échapper à l'agitation urbaine.`,
      p3: `Nous parlons couramment français, anglais et allemand.`,
      p4: `Nous n'acceptons pas d'animaux domestiques.`,
    },
    facilitiesAndServices: {
      title: 'Prestations',
      parking: 'Parking',
      terrace: 'Terrasse',
      garden: 'Jardin',
      breakfast: 'Petit-déjeuner (du 10.06. au 20.09.)',
      restaurant: 'Restaurant à la carte(du 25.06. au 10.09.)',
      rentBike: 'Location de vélos et de scooters',
      transfers: 'Et transferts en voiture ou en bateau',
    },
    accommodations: {
      title: 'Chambres et Suites.',
      rooms: {
        title: 'la chambre',
        p1: `Dans chaque chambre(15m2) avec balcon, climatisation et chauffage, les 2 lits séparables sont grands et confortables. Il y a aussi un petit réfrigérateur encastré, une télévision et accès à Wi-Fi. Literie, serviettes de toilette, set de douche et sèche-cheveux sont fournis par la maison.`,
        p2: `Interdiction de fumer à l'intérieur de toute la maison, mais permis sur le balcon.`,
        p3: `Nous vous souhaitons un agréable séjour!`,
      },
      suites: {
        title: 'suites',
        p1: `Dans l'offre de Sidro il y a également des appartements de luxe récemment rénovés (depuis 2022). Tous les appartements se trouvent au 1er étage. Ils sont équipés comme appartement ou studio. Chaque appartement dispose d'une grande salle de bain avec une douche à l'italienne, d'une petite cuisine, d'une télévision intelligente, de la climatisation (possibilité de rafraîchissement/chauffage) et de lits anatomiques.`,
        p2: `Sidro propose un service de nettoyage quotidien et met à disposition du linge de lit, des serviettes, un ensemble de nettoyage, un set de toilette pour la salle de bain, des produits de vaisselle et des épices de base pour la cuisine.`,
        p3: 'Les réservations peuvent être effectuées par e-mail ou par téléphone.',
      },
    },
    destinations: {
      title: 'destinations',
      heritage: {
        np: '2 parcs nationaux impressionnants',
        heritage: `2 sites du patrimoine mondial de l'UNESCO`,
        castle: '11 fortifications médiévales',
        culture: '230 monuments culturels',
      },
    },
    gallery: {
      title: 'galerie',
    },
  },
  accommodations: {
    rooms: {
      title: 'La chambre',
      p1: `Situé à Vodice, à seulement 200 mètres de la plage, le B&B Sidro dispose d'un jardin, d'une terrasse et d'un restaurant. Šibenik se trouve à 20 minutes de route.`,
      p2: `Les chambres climatisées comprennent toutes un balcon, une télévision par satellite et un réfrigérateur. Leur salle de bains privative est pourvue d'une douche et d'un sèche-cheveux. Certaines chambres offrent une vue sur la mer.`,
      p3: `L'établissement sert un petit-déjeuner chaque matin. Vous pourrez également dîner dans le restaurant sur place ou vous détendre avec un verre au bar.`,
      p4: `Le B&B Sidro met également à votre disposition une connexion Wi-Fi gratuite dans les parties communes, un parking privé gratuit sur place ainsi qu'un service de bagagerie. De nombreuses activités s'offrent à vous sur place ou dans les environs, notamment la randonnée à vélo. L'aéroport de Split se situe à 48 km.`,
    },
    suites: {
      title: 'Les appartements',
      p1: `Les appartements SIDRO, une propriété avec un jardin, une terrasse et un bar, sont situés à Vodice, à 400 mètres de la plage de Hangar, à 600 mètres de la plage de Male Vrulje, ainsi qu'à 12 km de l'hôtel de ville de Šibenik. Le logement climatisé se trouve à 400 mètres de la plage Impériale, et les clients peuvent bénéficier d'un parking privé sur place et d'une connexion WiFi gratuite. Le Musée du patrimoine de Biograd est à 37 km, et la marina ACI de Vodice est à 1,1 km de l'appartement.`,
      p2: `Chaque unité est équipée d'une salle de bains privative, tandis que certaines chambres sont équipées d'une cuisine entièrement équipée.`,
      p3: `Le Fort Barone se trouve à 12 km de l'appartement, tandis que la Marina Kornati est à 37 km. L'aéroport le plus proche est l'aéroport de Zadar, à 64 km des appartements SIDRO.`,
    },
    amenities: {
      bathroom: {
        title: 'Salle de bains',
        privateBathroom: 'Salle de bains privative',
      },
      backyard: {
        title: 'En extérieur',
        sunBath: 'Terrasse bien exposée',
        terrace: 'Terrasse',
        garden: 'Jardin',
      },
      activity: {
        title: 'Activités',
        rentBike: 'Location de vélos - en supplément',
        biking: 'Cyclisme',
        playground: 'Aire de jeux pour enfants',
      },
      food: {
        title: 'Restauration',
        menu: 'Menus pour régimes spéciaux (sur demande)',
        bar: 'Bar',
        restaurant: 'Restaurant',
      },
      internet: {
        title: 'Internet',
        wifi: `Une connexion Wi-Fi est disponible dans tout l'établissement gratuitement.`,
      },
      parking: {
        title: 'Parking',
        freeParking:
          'Un parking gratuit et privé est disponible sur place (uniquement sur réservation).',
      },
      services: {
        title: 'Services',
        transfers: 'Service de navette - en supplément',
        checkIn: 'Enregistrement / départ privé',
        luggage: 'Bagagerie',
        airportTransfers: 'Navette aéroport - en supplément',
      },
      reception: {
        title: 'Réception',
        bill: 'Facture fournie sur demande',
      },
      others: {
        title: 'Général',
        ac: 'Climatisation',
        noSmoking: 'Établissement entièrement non-fumeurs',
        heating: 'Chauffage',
        nonSmokingRooms: 'Chambres non-fumeurs',
      },
      languages: {
        title: 'Langues parlées',
        german: 'Allemand',
        english: 'Anglais',
        french: 'Français',
        croatian: 'Croate',
      },
    },
  },
  contactUs: {
    title: 'contactez-nous',
  },
};
