import ScrollAnimation from '../../../../utils/ScrollAnimation';
import Variables from '../../../../style/variables.scss';
import Constants from '../../../../utils/Constants';
import FacilitiesAndServicesList from './FacilitiesAndServicesList';

const FacilitiesAndServices = ({ lang }: { lang: Locale.Keys }) => {
  const iconsHeight: number = 24;

  const services = FacilitiesAndServicesList({ lang });

  return (
    <div id={Constants.FACILITIES_AND_SERVICES} className='services'>
      <div className='services_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeIn' dellay={100}>
            <h2 className='text_center'>
              {lang.home.facilitiesAndServices.title}
            </h2>
          </ScrollAnimation>
          <div className='services_container_details'>
            {services.map((service) => (
              <ScrollAnimation
                animateIn='fadeInUp'
                duration={500}
                delay={services.indexOf(service) * 50 + 100}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox={service.viewBox}
                  height={iconsHeight}
                  fill={Variables.primaryColor}
                >
                  {service.path}
                </svg>
                {service.description.toUpperCase()}
              </ScrollAnimation>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default FacilitiesAndServices;
