import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import DestinationsList from './DestinationsList';

const Destinations = ({ lang }: { lang: Locale.Keys }) => {
  const destinationsList = DestinationsList({ lang });

  const slickSettingsImgs = {
    focusOnSelect: true,
    infinite: true,
    centerMode: true,
    centerPadding: '200px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '120px',
        },
      },
      {
        breakpoint: 801,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div id={Constants.DESTINATIONS} className='destinations'>
      <div className='destinations_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
            <h2 className='text_center'>{lang.home.destinations.title}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn' delay={150}>
            <Slider {...slickSettingsImgs}>
              {destinationsList.DestinationList.map((destination) => (
                <div className='destinations_container_slider_container'>
                  <div className='destinations_container_slider_container_img'>
                    <img src={destination.img} alt={destination.title} />
                  </div>
                  <div className='destinations_container_slider_container_title'>
                    <h2 className='text_center'>{destination.title}</h2>
                    <a
                      href={destination.link}
                      target='_blank'
                      rel='noreferrer'
                      className='opacity_transition_high'
                    >
                      {lang.showMore.toUpperCase()}
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
          </ScrollAnimation>

          <div className='destinations_container_tiles'>
            {destinationsList.HeritageList.map((heritage) => (
              <ScrollAnimation
                animateIn='fadeInUp'
                delay={
                  250 + destinationsList.HeritageList.indexOf(heritage) * 50
                }
              >
                <div className='destinations_container_tiles_container'>
                  <div className='destinations_container_tiles_container_img'>
                    <img src={heritage.img} alt={heritage.title} />
                  </div>
                  <div className='destinations_container_tiles_container_title'>
                    <h2 className='text_center'>{heritage.title}</h2>
                    <a
                      href={heritage.link}
                      target='_blank'
                      rel='noreferrer'
                      className='opacity_transition_high'
                    >
                      {lang.showMore.toUpperCase()}
                    </a>
                  </div>
                </div>
              </ScrollAnimation>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Destinations;
