export const de: Locale.Keys = {
  bookNow: 'Buchen Sie jetzt',
  showMore: 'Zeig mehr',
  home: {
    menu: 'home',
    about: {
      title: 'sidro - vodice',
      p1: 'Die Pension Sidro ist ein kleines Familienhotel der Familie Marić mit einer langjährigen Tradition von 40 Jahren.',
      p2: 'Sie befindet sich in einem ruhigen Teil von Vodice, nur 150 Meter vom Hauptstrand der Stadt und 500 Meter vom Stadtzentrum entfernt. Die Unterkunft ist von einer prachtvollen Terrasse, Mediterranpflanzen, und Grünflächen umgeben. Ideal für Erholung und Flucht von dem städtischen Trubel.',
      p3: 'Wir sprechen fließend deutsch, englisch und französisch.',
      p4: 'Wir nehmen keine Haustiere an.',
    },
    facilitiesAndServices: {
      title: 'Leistungen',
      parking: 'Parking',
      terrace: 'Terrasse',
      garden: 'Garten',
      breakfast: 'Frühstück (von 10.06. bis 20.09.)',
      restaurant: 'Restaurant à la carte (von 25.06. bis 10.09.)',
      rentBike: 'Fahrrad - und Rollervermietung',
      transfers: 'Transfers mit Auto und Boot',
    },
    accommodations: {
      title: 'Zimmer und Suiten',
      rooms: {
        title: 'das zimmer',
        p1: 'In jedem Zimmer(15m2) mit Balkon, Klimaanlage und Heizung sind die 2 trennbare Betten groß und bequem. Es gibt einen kleinen eingebauten Kühlschrank, ein Fernsehen und Zugang zu Wi-Fi. Bettwäsche, Handtücher, Duscheset und Haartrockner stehen zur Verfügung.',
        p2: 'Rauchen verboten im Innern des ganzen Hauses, auf Balkon jedoch erlaubt.',
        p3: 'Wir wünschen Ihnen einen angenehmen Aufenthalt!',
      },
      suites: {
        title: 'apartments',
        p1: 'In seinem Angebot hat Sidro auch neu renovierte Luxusapartments ( seit 2022). Alle Apartments befinden sich auf der 1. Etage. Sie sind ausgestattet wie Apartments oder Studios. Jedes Apartment verfügt über ein großes Badezimmer mit begehbarer Dusche, eine kleine Küche, ein Smart-TV, Klimaanlage (mit Kühl- und Heizmöglichkeit) und anatomische Betten.',
        p2: 'Sidro bietet tägliche Reinigungsdienste an und stellt zur Verfügung Bettwäsche, Handtücher, Reinigungsset, Badset, Geschirrspülmittel und grundlegende Gewürze zum Kochen.',
        p3: 'Reservierungen können per E-Mail oder Telefon vorgenommen werden.',
      },
    },
    destinations: {
      title: 'reiseziele',
      heritage: {
        np: '2 atemberaubende nationalparks',
        heritage: '2 UNESCO welterbestatten',
        castle: '11 mittelalterliche befestigungsanlagen',
        culture: '230 kulturdenkmaler',
      },
    },
    gallery: {
      title: 'galerie',
    },
  },
  accommodations: {
    rooms: {
      title: 'Das Zimmer',
      p1: 'Das B&B Sidro liegt in Vodice, nur 200 m vom Strand entfernt, und bietet einen Garten, eine Terrasse und ein Restaurant. Šibenik erreichen Sie nach 20 Fahrminuten.',
      p2: 'Alle Zimmer sind klimatisiert und verfügen über einen Balkon, Sat-TV und einen Kühlschrank. Das eigene Bad ist mit einer Dusche und einem Haartrockner ausgestattet. Einige Zimmer bieten auch Meerblick.',
      p3: 'Morgens wird im B&B Sidro ein Frühstück serviert. Sie können im Restaurant der Unterkunft speisen oder bei einem Getränk in der hauseigenen Bar entspannen.',
      p4: 'Die weiteren Annehmlichkeiten umfassen kostenfreies WLAN in den öffentlichen Bereichen, kostenfreie Privatparkplätze und eine Gepäckaufbewahrung. An der Unterkunft und in der Umgebung können Sie zahlreichen Aktivitäten wie Radfahren nachgehen. Der Flughafen Split liegt 48 km entfernt.',
    },
    suites: {
      title: 'Die Apartments',
      p1: 'Die SIDRO Apartments, eine Unterkunft mit einem Garten, einer Terrasse und einer Bar, befinden sich in Vodice, 400 Meter vom Hangar Beach, 600 Meter vom Male Vrulje Beach und 12 km vom Rathaus von Šibenik entfernt. Die klimatisierte Unterkunft liegt 400 Meter vom Imperial Beach entfernt, und die Gäste können von den auf dem Gelände verfügbaren Privatparkplätzen und kostenlosem WLAN profitieren. Das Biograd Heritage Museum ist 37 km entfernt, und die ACI Marina Vodice ist 1,1 km vom Apartment entfernt.',
      p2: 'Jede Einheit ist mit einem eigenen Badezimmer ausgestattet, während einige Zimmer über eine voll ausgestattete Küche verfügen.',
      p3: 'Die Barone Festung ist 12 km vom Apartment entfernt, während die Kornati Marina 37 km entfernt ist. Der nächstgelegene Flughafen ist der Flughafen Zadar, 64 km von den SIDRO Apartments entfernt.',
    },
    amenities: {
      bathroom: {
        title: 'Badezimmer',
        privateBathroom: 'Eigenes Badezimmer',
      },
      backyard: {
        title: 'Außenbereich',
        sunBath: 'Sonnenterrasse',
        terrace: 'Terrasse',
        garden: 'Garten',
      },
      activity: {
        title: 'Aktivitäten',
        rentBike: 'Fahrradverleih - zusätzliche Gebühren',
        biking: 'Radfahren',
        playground: 'Kinderspielplatz',
      },
      food: {
        title: 'Speisen & Getränke',
        menu: 'Speisen für spezielle Ernährungsbedürfnisse (auf Anfrage)',
        bar: 'Bar',
        restaurant: 'Restaurant',
      },
      internet: {
        title: 'Internet',
        wifi: 'WLAN ist in allen Bereichen nutzbar und ist kostenfrei.',
      },
      parking: {
        title: 'Parkmöglichkeiten',
        freeParking:
          'Private Parkplätze stehen kostenfrei an der Unterkunft (Reservierung ist erforderlich) zur Verfügung.',
      },
      services: {
        title: 'Dienstleistungen',
        transfers: 'Shuttleservice - zusätzliche Gebühren',
        checkIn: 'Privater Check-in / -out',
        luggage: 'Gepäckaufbewahrung',
        airportTransfers: 'Flughafenshuttle - Zusätzliche Gebühren',
      },
      reception: {
        title: 'Rezeptionsservice',
        bill: 'Rechnung auf Anfrage',
      },
      others: {
        title: 'Allgemein',
        ac: 'Klimaanlage',
        noSmoking:
          'Nichtraucherunterkunft (Alle öffentlichen und privaten Bereiche sind Nichtraucherzonen)',
        heating: 'Heizung',
        nonSmokingRooms: 'Nichtraucherzimmer',
      },
      languages: {
        title: 'Gesprochene Sprachen',
        german: 'Deutsch',
        english: 'Englisch',
        french: 'Französisch',
        croatian: 'Kroatisch',
      },
    },
  },
  contactUs: {
    title: 'kontaktiere uns',
  },
};
