import { scroller } from 'react-scroll';
import Constants from './Constants';

const scrollTarget = (target: string) =>
  scroller.scrollTo(target, {
    spy: true,
    smooth: true,
    duration: 500,
    
  })

const scrollToPage = async (
  target: string,
  location: any,
  navigate: any,
  page?: boolean
) => {
  if (page) {
    await navigate(`/${target}`);
    scrollTarget(target);
  } else if (location.pathname !== '/' && target !== Constants.FOOTER) {
    await navigate('/');
    scrollTarget(target);
  } else {
    scrollTarget(target);
  }
};

export default scrollToPage;
