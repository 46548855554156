export const hr: Locale.Keys = {
  bookNow: 'Rezerviraj',
  showMore: 'Prikaži više',
  home: {
    menu: 'home',
    about: {
      title: 'sidro - vodice',
      p1: 'B&B Sidro je mali obiteljski pansion obitelji Marić sa dugogodišnjom tradicijom od 40 godina.',
      p2: 'Smješten je u mirnom dijelu Vodica. Udaljen je 150m od glavne gradske plaže i 500m od samog centra grada. Objekt je okružen prostranom kamenom terasom, mediteranskim biljem i zelenilom.  Idealno je za odmor i bijeg od urbane vreve.',
      p3: 'Govorimo tečno engleski, njemački i francuski.',
      p4: 'Ne primamo kućne ljubimce.',
    },
    facilitiesAndServices: {
      title: 'Dodatne usluge',
      parking: 'Parking',
      terrace: 'Terasa',
      garden: 'Vrt',
      breakfast: 'doručak (od 10.06. do 20.09.)',
      restaurant: 'Restoran à la carte (od 25.06. do 10.09.)',
      rentBike: 'Iznajmljivanje bicikla i motora',
      transfers: 'Transferi autom i brodom',
    },
    accommodations: {
      title: 'Sobe i apartmani',
      rooms: {
        title: 'sobe',
        p1: 'U svakoj sobi(15 m2) sa balkonom, klimatizacijom i grijanjem, su 2 velika i udobna kreveta spojena ili odvojena. Postoji mali ugradbeni frižider, televizija, i pristup Wi-Fi-u. Kuća stavlja na raspolaganje posteljinu, ručnike, set za tuširanje i fen.',
        p2: 'Zabranjeno pušenje unutar cijele kuće, na balkonu dozvoljeno.',
        p3: 'Želimo vam ugodan boravak!',
      },
      suites: {
        title: 'apartmani',
        p1: 'Sidro stavlja u ponudu i novo uređene luksuzne apartmane (godina proizvodnje 2022.) Svi apartmani se nalaze na 1. katu. Opremljeni su kao apartman ili studio. Svaki apartman ima veliko kupatilo sa walk-in tušem, malu kuhinju, smart-tv, klimatizaciju (mogućnost hlađenje/grijanje) i anatomske krevete.',
        p2: 'Sidro nudi svakodnevno čišćenje i stavlja na raspolaganje posteljinu, ručnike, set za čišćenje, toalet-pribor za kupatilo, sredstva za pranje posuđa i osnovne začine za kuhanje.',
        p3: 'Rezervirati se može mailom Ili telefonom.',
      },
    },
    destinations: {
      title: 'destinacije',
      heritage: {
        np: '2 očaravajuća nacionalna parka',
        heritage: '2 lokaliteta na popisu svjetske baštine UNESCO-a',
        castle: '11 srednjovjekovnih utvrda',
        culture: '230 spomenika kulture',
      },
    },
    gallery: {
      title: 'galerija',
    },
  },
  accommodations: {
    rooms: {
      title: 'Sobe',
      p1: 'Smještaj s uslugom doručka Sidro nalazi se u Vodicama, na samo 200 metara od plaže, a nudi vrt, terasu i restoran. Šibenik je udaljen 20 minuta vožnje.',
      p2: 'Sve su sobe klimatizirane i sadrže balkon, TV sa satelitskim programima i hladnjak. U vlastitoj kupaonici na raspolaganju su sušilo za kosu i tuš. Neke od soba nude pogled na more.',
      p3: 'Svakog se jutra u smještaju s uslugom doručka Sidro poslužuje doručak. Gosti mogu objedovati u restoranu u okviru objekta ili se mogu opustiti uz piće u hotelskom baru.',
      p4: 'Dodatni sadržaji uključuju besplatni WiFi u zajedničkim prostorijama, besplatno privatno parkiralište u okviru objekta i skladište prtljage. U okviru objekta i u okolici gosti mogu uživati u brojnim aktivnostima poput vožnje bicikla. Splitska zračna luka udaljena je 48 km.',
    },
    suites: {
      title: 'Apartmani',
      p1: 'Apartmani SIDRO, smješteni u Vodicama, s vrtom, terasom i barom, udaljeni su 400 metara od plaže Hangar, 600 metara od plaže Male Vrulje, te 12 km od gradske vijećnice Šibenika. Klimatizirani smještaj nalazi se 400 metara od plaže Imperial, a gosti mogu koristiti privatno parkiralište koje je dostupno na licu mjesta, kao i besplatni WiFi. Muzej baštine Biograda udaljen je 37 km, a ACI Marina Vodice 1,1 km od apartmana.',
      p2: 'Svaka jedinica opremljena je privatnom kupaonicom, dok su neke sobe opremljene potpuno opremljenom kuhinjom.',
      p3: 'Tvrđava Barone udaljena je 12 km od apartmana, dok je Kornati Marina udaljena 37 km. Najbliža zračna luka je Zračna luka Zadar, udaljena 64 km od apartmana SIDRO.',
    },
    amenities: {
      bathroom: {
        title: 'Kupaonica',
        privateBathroom: 'Vlastita kupaonica',
      },
      backyard: {
        title: 'Dvorište',
        sunBath: 'Terasa za sunčanje',
        terrace: 'Terasa',
        garden: 'Vrt',
      },
      activity: {
        title: 'Aktivnosti',
        rentBike: 'Najam bicikla - dodatna naknada',
        biking: 'biciklizam',
        playground: 'dječje igralište',
      },
      food: {
        title: 'Hrana i piće',
        menu: 'Jelovnik za poseban program ishrane (na zahtjev)',
        bar: 'Bar',
        restaurant: 'Restoran',
      },
      internet: {
        title: 'Internet',
        wifi: 'Bežični pristup internetu dostupan je u cijelom objektu i ne naplaćuje se.',
      },
      parking: {
        title: 'Parking',
        freeParking:
          'Besplatno privatno parkiralište dostupno je u okviru objekta i potrebna je rezervacija.',
      },
      services: {
        title: 'Usluge',
        transfers: 'Usluga prijevoza - dodatna naknada',
        checkIn: 'Privatna prijava / odjava',
        luggage: 'Skladište prtljage',
        airportTransfers: 'Prijevoz iz / do zračne luke - dodatna naknada',
      },
      reception: {
        title: 'Usluge resepcije',
        bill: 'Može izdati račun',
      },
      others: {
        title: 'Općenito',
        ac: 'Klima-uređaj',
        noSmoking: 'Zabranjeno pušenje u čitavom objektu',
        heating: 'Grijanje',
        nonSmokingRooms: 'Sobe za nepušače',
      },
      languages: {
        title: 'Usluga dostupna na',
        german: 'Njemački',
        english: 'Engleski',
        french: 'Francuski',
        croatian: 'Hrvatski',
      },
    },
  },
  contactUs: {
    title: 'kontaktirajte nas',
  },
};
