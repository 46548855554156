import ScrollAnimation from '../../utils/ScrollAnimation';

const Amenities = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div className='accommodation_container_info_details'>
      <div>
        <ScrollAnimation animateIn='fadeInUp'>
          <p>{lang.accommodations.amenities.bathroom.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.bathroom.privateBathroom}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={50}>
          <p>{lang.accommodations.amenities.backyard.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.backyard.sunBath}</li>
            <li>{lang.accommodations.amenities.backyard.terrace}</li>
            <li>{lang.accommodations.amenities.backyard.garden}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={100}>
          <p>{lang.accommodations.amenities.activity.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.activity.rentBike}</li>
            <li>{lang.accommodations.amenities.activity.biking}</li>
            <li>{lang.accommodations.amenities.activity.playground}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={150}>
          <p>{lang.accommodations.amenities.food.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.food.menu}</li>
            <li>{lang.accommodations.amenities.food.bar}</li>
            <li>{lang.accommodations.amenities.food.restaurant}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={200}>
          <p>{lang.accommodations.amenities.internet.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.internet.wifi}</li>
          </ul>
        </ScrollAnimation>
      </div>
      <div>
        <ScrollAnimation animateIn='fadeInUp' delay={250}>
          <p>{lang.accommodations.amenities.parking.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.internet.wifi}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={250}>
          <p>{lang.accommodations.amenities.services.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.services.transfers}</li>
            <li>{lang.accommodations.amenities.services.checkIn}</li>
            <li>{lang.accommodations.amenities.services.luggage}</li>
            <li>{lang.accommodations.amenities.services.airportTransfers}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={300}>
          <p>{lang.accommodations.amenities.reception.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.reception.bill}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={350}>
          <p>{lang.accommodations.amenities.others.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.others.ac}</li>
            <li>{lang.accommodations.amenities.others.noSmoking}</li>
            <li>{lang.accommodations.amenities.others.heating}</li>
            <li>{lang.accommodations.amenities.others.nonSmokingRooms}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' delay={400}>
          <p>{lang.accommodations.amenities.languages.title.toUpperCase()}</p>
          <ul>
            <li>{lang.accommodations.amenities.languages.german}</li>
            <li>{lang.accommodations.amenities.languages.english}</li>
            <li>{lang.accommodations.amenities.languages.french}</li>
            <li>{lang.accommodations.amenities.languages.croatian}</li>
          </ul>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Amenities;
