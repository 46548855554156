import { useEffect } from 'react';
import Variables from '../../../style/variables.scss';
import Constants from '../../../utils/Constants';
import ScrollAnimation from '../../../utils/ScrollAnimation';
import Amenities from '../Amenities';
import Gallery from '../../home/components/04_gallery/Gallery';

const images = require.context(
  '../../../assets/imgs/00_accommodations/02_suites',
  true
);
const imageList = images.keys().map((image: any) => images(image));

const Suites = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = `${Constants.PROPERTY_NAME} - ${lang.accommodations.suites.title}`;
  }, [lang]);

  return (
    <div id={Constants.SUITES} className='accommodation_wrapper'>
      <div className='accommodation_hero'>
      <img src={imageList[2]} alt={`${lang.accommodations.suites.title} hero`} />
        <div className='accommodation_hero_title'>
          <h1>{lang.accommodations.suites.title}</h1>
        </div>
      </div>
      <div className='accommodation_img'>
        {Constants.separatorColoredDown(Variables.secondaryColor)}
      </div>
      <div className='accommodation_container container'>
        <h1 hidden>{lang.accommodations.suites.title}</h1>
        <div className='accommodation_container_info'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={100}
          >
            <div className='accommodation_container_info_text'>
              <ScrollAnimation animateIn='fadeInUp'>
                {lang.accommodations.suites.p1}
              </ScrollAnimation>
              <br />
              <ScrollAnimation animateIn='fadeInUp' delay={150}>
                {lang.accommodations.suites.p2}
                <br />
              </ScrollAnimation>
              <br />
              <ScrollAnimation animateIn='fadeInUp' delay={200}>
                {lang.accommodations.suites.p3}
                <br />
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' delay={300}>
                <div className='accommodation_container_info_text_button'>
                  <a
                    href={Constants.bookNowLink}
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low'
                  >
                    <div>{lang.bookNow.toUpperCase()}</div>
                  </a>
                </div>
                <Amenities lang={lang} />
              </ScrollAnimation>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <Gallery lang={lang} imageList={imageList} />
    </div>
  );
};

export default Suites;
