import { useLocation, useNavigate } from 'react-router-dom';
import scrollToPage from '../../utils/ScrollToLink';
import smallLogo from '../../assets/imgs/small_logo.svg';
import Constants from '../../utils/Constants';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div id={Constants.FOOTER} className='footer'>
      <div className='footer_container container'>
        <div
          className='footer_container_logo'
          onClick={() => scrollToPage('home', location, navigate)}
        >
          <img src={smallLogo} alt={Constants.PROPERTY_NAME + ' small logo'} />
        </div>
        <div className='footer_container_contact'>
          <p>
            <a
              href='https://maps.app.goo.gl/kjE3us6UeaQ1kqt7A'
              target='_blank'
              rel='noreferrer'
              className='opacity_transition_low'
            >
              Splitska 2
            </a>
          </p>
          <p>22211 Vodice, Croatia</p>
          <p>
            <a href='tel:+385 95 3222 222' className='opacity_transition_low'>
              +385 95 3222 222
            </a>
          </p>
          <p>
            <a
              href='mailto:sidrovodice@gmail.com'
              className='opacity_transition_low'
            >
              sidrovodice@gmail.com
            </a>
          </p>
        </div>
        <div className='footer_container_bottom'>
          <p>Copyright © Sidro {new Date().getFullYear()}.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
