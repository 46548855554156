export const en: Locale.Keys = {
  bookNow: 'Book Now',
  showMore: 'Show More',
  home: {
    menu: 'home',
    about: {
      title: 'sidro - vodice',
      p1: 'B&B Sidro is a small family-run guesthouse owned by the Marić family with a long-standing tradition of 40 years.',
      p2: 'It is located in a peaceful part of Vodice, just 150m away from the main city beach and 500m from the city center.The property is surrounded by a spacious stone terrace, mediterranean plants, and greenery, providing an ideal setting for relaxation and escape from the urban hustle.',
      p3: 'We are fluent in English, German, and French.',
      p4: 'Please note that we do not accept pets.',
    },
    facilitiesAndServices: {
      title: 'Services',
      parking: 'Parking',
      terrace: 'Terrace',
      garden: 'Garden',
      breakfast: 'Breakfast (from June 10th to September 20th)',
      restaurant: 'à la carte restaurant (from June 25th to September 10th)',
      rentBike: 'Bicycle and motorcycle rental',
      transfers: 'Transfers by car and boat',
    },
    accommodations: {
      title: 'Rooms and Suites',
      rooms: {
        title: 'rooms',
        p1: 'In each room (15 m2) with a balcony, air conditioning, and heating, there are 2 large and comfortable beds that can be connected or separated. There is a small built-in refrigerator, television, and Wi-Fi access. The house provides bed linen, towels, a shower set, and a hairdryer.',
        p3: 'Smoking is prohibited throughout the entire house, but it is allowed on the balcony.',
        p2: 'We wish you a pleasant stay!',
      },
      suites: {
        title: 'suites',
        p1: 'Sidro is introducing newly renovated luxury apartments (manufactured in 2022). All apartments are located on the 1st floor and are furnished as either one-bedroom apartments or studios. Each apartment features a spacious bathroom with a walk-in shower, a small kitchen, a smart TV, air conditioning (with cooling/heating options), and ergonomic beds',
        p2: 'Sidro provides daily cleaning services and offers bed linen, towels, a cleaning kit, bathroom toiletries, dishwashing essentials, and basic cooking spices.',
        p3: 'Reservations can be made via email or phone.',
      },
    },
    destinations: {
      title: 'destinations',
      heritage: {
        np: '2 stunning national parks',
        heritage: '2 UNESCO world heritage sites',
        castle: '11 medieval fortifications',
        culture: '230 cultural monuments',
      },
    },
    gallery: {
      title: 'gallery',
    },
  },
  accommodations: {
    rooms: {
      title: 'Rooms',
      p1: 'Located in Vodice, just 656 feet from the beach, B&B Sidro offers a garden, terrace and a restaurant. Šibenik is a 20-minute drive away.',
      p2: 'All rooms are air-conditioned and feature a balcony, satellite TV and a refrigerator. The private bathroom includes a hairdryer and shower. Some rooms also have a view of the sea.',
      p3: `A breakfast is provided each morning at B&B Sidro. Guests can also dine in the on-site restaurant or relax with a drink in the hotel's bar.`,
      p4: 'Additional features include free WiFi access in the public areas, free private parking on site and luggage storage facilities. An array of activities can be enjoyed on site or in the surroundings, including cycling. Split Airport is 48 km away.',
    },
    suites: {
      title: 'Suites',
      p1: 'SIDRO Apartments, a property with a garden, a terrace and a bar, is located in Vodice, 400 metres from Hangar Beach, 600 metres from Male Vrulje Beach, as well as 12 km from Sibenik Town Hall. The air-conditioned accommodation is 400 metres from Imperial Beach, and guests can benefit from private parking available on site and free WiFi. Biograd Heritage Museum is 37 km away and ACI Marina Vodice is 1.1 km from the apartment.',
      p2: 'Each unit is equipped with a private bathroom, while some rooms are equipped with a fully equipped kitchen.',
      p3: 'Barone Fortress is 12 km from the apartment, while Kornati Marina is 37 km away. The nearest airport is Zadar Airport, 64 km from SIDRO Apartments.',
    },
    amenities: {
      bathroom: {
        title: 'Bathroom',
        privateBathroom: 'Private Bathroom',
      },
      backyard: {
        title: 'Outdoors',
        sunBath: 'Sun deck',
        terrace: 'Terrace',
        garden: 'Garden',
      },
      activity: {
        title: 'Activities',
        rentBike: 'Bicycle rental - additional charge',
        biking: 'Cycling',
        playground: 'Playground',
      },
      food: {
        title: 'Food & drink',
        menu: 'Special diet meals (on request)',
        bar: 'Bar',
        restaurant: 'Restaurant',
      },
      internet: {
        title: 'Internet',
        wifi: 'WiFi is available in all areas and is free of charge.',
      },
      parking: {
        title: 'Parking',
        freeParking:
          'Free private parking is available on site (reservation is needed).',
      },
      services: {
        title: 'Services',
        transfers: 'Shuttle service - additional charge',
        checkIn: 'Private check-in / out',
        luggage: 'Baggage storage',
        airportTransfers: 'Airport shuttle - additional charge',
      },
      reception: {
        title: 'Front desk services',
        bill: 'Invoice provided',
      },
      others: {
        title: 'General',
        ac: 'Air conditioning',
        noSmoking: 'Smoke-free property',
        heating: 'Heating',
        nonSmokingRooms: 'Non-smoking rooms',
      },
      languages: {
        title: 'Languages spoken',
        german: 'German',
        english: 'English',
        french: 'French',
        croatian: 'Croatian',
      },
    },
  },
  contactUs: {
    title: 'contact us',
  },
};
