import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useLanguageSwitcher } from './locale/useLanguageSwitcher';
import { en } from './locale/en';
import { hr } from './locale/hr';
import { de } from './locale/de';
import { fr } from './locale/fr';
import Layout from './pages/Layout';
import Home from './pages/home/Home';
import Rooms from './pages/accommodations/01_rooms/Rooms';
import OnReloadScrollToTop from './utils/OnReloadScrollToTop';
import Constants from './utils/Constants';
import { useEffect } from 'react';
import Suites from './pages/accommodations/02_suites/Suites';

const App: React.FC = () => {
  const { currentLanguage, changeLanguage, getLanguageFromURL } =
    useLanguageSwitcher();

  const lang: Locale.Keys =
    currentLanguage === Constants.lang.en
      ? en
      : currentLanguage === Constants.lang.hr
      ? hr
      : currentLanguage === Constants.lang.de
      ? de
      : currentLanguage === Constants.lang.fr
      ? fr
      : en;

  let doc: HTMLElement = document.documentElement;

  OnReloadScrollToTop();

  const setHight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  window.addEventListener('resize', setHight);

  useEffect(() => {
    setHight();
  }, []);

  useEffect(() => {
    getLanguageFromURL();
  }, [getLanguageFromURL]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <Layout
              currentLanguage={currentLanguage}
              changeLanguage={changeLanguage}
              lang={lang}
            />
          }
        >
          <Route index element={<Home lang={lang} />} />
          <Route path={Constants.ROOMS} element={<Rooms lang={lang} />} />
          <Route path={Constants.SUITES} element={<Suites lang={lang} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
